import React from 'react';

import { ProductBox } from '../components/boxen';
import Seo from '../components/seo';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


const SoftwarePage = () => {

  return(
  <>
    <Seo title="Software" description="WD Software and Hosted Services"/>
    <Paper sx={{padding: '0.5em 0.4em 1em'}}>
      <Typography variant='h4' component='h1'>
        Software
      </Typography>
        We specialize in process control and monitoring software
        for ecommerce and business cloud integrations.

      <Typography variant='h5' component='h2' mt={2}>
        E-Commerce support
      </Typography>

      <Box display='flex'>
        <ProductBox maintitle="custom" to="/contact" linktitle="contact">
          Host your own private version of our software
          on AWS.
        </ProductBox>

        <ProductBox maintitle="ceres"
          href='https://ceres.wernerdigital.com'
          linktitle='sign&nbsp;in'
          announce="open beta"
        >
           Multi marketplace tools
        </ProductBox>

        <ProductBox maintitle="wbench"
          href='https://wbench.wernerdigital.com'
          linktitle='sign&nbsp;in'
        >
           Workbench tools and building blocks
        </ProductBox>

      </Box>

      <Typography variant='h5' component='h2' mt={2}>
        General
      </Typography>
      <Box display='flex'>
        <ProductBox maintitle="Timers"
          href='https://scottschedule.wernerdigital.com'
          linktitle='sign&nbsp;in'
        >
          Complex timers for caregivers
        </ProductBox>

        <ProductBox maintitle="Canvas" to='/demomb' linktitle='demo'>
          HTML5 Canvas demo
        </ProductBox>

      </Box>

      <Typography variant='h5' component='h2' mt={2}>
        Archive
      </Typography>
      <Typography>
        Software available for reference use only.
      </Typography>
      <Box display='flex'>
        <ProductBox maintitle="mtools" to='/mtools'>
          Tools for US ecommerce marketplaces.  Python scripts
          for examples and customization to automate and extend
          your current systems.
        </ProductBox>
      </Box>

    </Paper>
  </>
)}

export default SoftwarePage
